import React, { Suspense, lazy, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import * as Sentry from "@sentry/react";
import VetsmithSubscribe from '../legacy_components/UI_V2_Components/Vetsmith/VetsmithSubscribe';
import history from '../services/history';
import ErrorBoundary from '../legacy_components/components/common/ErrorBoundary';
import { redirector } from '../services/redirector';
import PageConstant from '../constants/PageConstant';

import '../assets/css/index.css';
import '../assets/css/flexboxgrid.css';
import '../assets/css/App.css';
import '../assets/css/Animations.css';
import '../assets/css/Content.css';
import '../components/Common/common.css';
import '../legacy_components/UI_V2_Components/layout/Layout.css';

const LandingPageContainer = lazy(() => import('../../containers/LandingPage/LandingPageContainer'));
const App = lazy(() => import('./App2'));

const Root = () => {
    const authenticatedAndGotToken = () => {
        const authenticated = localStorage.getItem('authenticated') === 'true';
        const adminAuthenticated = localStorage.getItem('adminAuthenticated') === 'true'
        const adminToken = localStorage.getItem('adminToken');
        const token = localStorage.getItem('token');
        const identity = localStorage.getItem('loggingIdentity');
        const path = window.location.pathname; /* ONLY APPLY FOR LANDING PAGE */
        const url = window.location.search;
        const params = queryString.parse(url);

        if (identity) {
            const parsedIdentity = JSON.parse(identity);
            if (parsedIdentity) {
                Sentry.setUser(parsedIdentity);
            }
        }

        /* THERE IS THE CURRENT AUTHENTICATE CHECK IN APP2 SO WE JUST NEED TO REDIRECT TO LOGIN */
        const bypassAuthRedirectPath = [ '/lci_embed/play', '/embed/profile', '/login/reset_pass' ];
        if (bypassAuthRedirectPath.includes(path)) return;
        const localStorageRedirect = localStorage.getItem('redirect_url');
        if (params && params.token) {
            localStorage.clear();

            if (params.redirect || localStorageRedirect) {
                localStorage.setItem('redirect_url', params.redirect || localStorageRedirect);
            }
            if (params.notificationChannel) {
                localStorage.setItem('directJobApplicationNotificationChannel', params.notificationChannel);
            }
            /** 26TH JAN. redirect to /login and then the redirect path */
            redirector.to(PageConstant.TOLOGIN + '?token=' + params.token, params.redirect ? params.redirect : PageConstant.TOLOGIN);
        } else if (path === '/' && authenticated && token) {
            Sentry.setUser(null); // Logging out if any user exists
            redirector.to(PageConstant.LOGIN, params.redirect);
        } else if ((!adminAuthenticated || !adminToken) && (!authenticated || !token)) {
            localStorage.clear();
        }
    };

    useEffect(() => {
        console.log('Loading root page');
        authenticatedAndGotToken();
    }, []);

    return (

        <ErrorBoundary>
            <Suspense fallback={<div className='lds-ripple'><div></div></div>}>
                <Router history={history}>
                    <Switch>
                        {/* ANONYMOUS */}
                        <Route exact path="/" component={LandingPageContainer} />
                        <Route exact path="/vetsmith-subscribe/:code" component={VetsmithSubscribe} />
                        <App />
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    )
};

export default Root;